var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "device-manager-card" }, [
    _c("div", { staticClass: "fw500 flex bet a-center" }, [
      _c("div", {
        style: { "margin-top": "10px", "margin-right": "15px" },
        domProps: { textContent: _vm._s("#" + _vm.fleet.id) },
      }),
    ]),
    _c(
      "div",
      {
        staticClass: "device-manager-card__main",
        style: { width: "100%", display: "flex" },
      },
      [
        _c(
          "div",
          {
            staticClass: "device-manager-card__main_main",
            style: { width: "80%" },
            on: { click: _vm.data.click },
          },
          [
            _c("div", { staticClass: "device-manager-card__main_name" }, [
              _c("div", {
                staticClass: "grey",
                domProps: { textContent: _vm._s(_vm.$t("Branch name")) },
              }),
              _c("div", {
                domProps: { textContent: _vm._s(_vm.fleet.branch_name) },
              }),
            ]),
            _c("div", { staticClass: "device-manager-card__main_name" }, [
              _c("div", {
                staticClass: "grey",
                domProps: { textContent: _vm._s(_vm.$t("Type")) },
              }),
              _c("div", { domProps: { textContent: _vm._s(_vm.fleet.type) } }),
            ]),
            _c("div", { staticClass: "device-manager-card__main_name" }, [
              _c("div", {
                staticClass: "grey",
                domProps: { textContent: _vm._s(_vm.$t("Iot device")) },
              }),
            ]),
            _c("div", {
              domProps: { textContent: _vm._s(_vm.fleet.device_key) },
            }),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }